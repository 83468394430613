<template>
  <CModal
    @update:show="closeModal"
    title="User Edit"
    color="primary"
    :show.sync="showModal"
    centered
  >
    <CInput label="Name" placeholder="Enter your name" v-model="item.name" />
    <CInput label="Email" placeholder="Enter your email" v-model="item.email" />
    <CInput label="Phone" placeholder="Enter your phone" v-model="item.phone" />
  </CModal>
</template>

<script>
import { CModal, CInput } from "@coreui/vue/src";

export default {
  name: "UserChangeModal",
  props: ["show", "item"],
  data() {
    return {
      showModal: false,
    };
  },
  watch: {
    show: function (value) {
      this.showModal = value;
    },
  },
  methods: {
    closeModal(show, event, accept) {
      if (accept) {
        this.$emit("accept");
      } else {
        this.$emit("close");
      }
    },
  },
  components: {
    CModal,
    CInput,
  },
};
</script>

<style scoped></style>
